import Layout from "../components/Layout";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import React, { useEffect } from "react";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";
config.autoAddCss = false;
import { Amplify, Auth } from "aws-amplify";
import { QueryClientProvider, QueryClient } from "react-query";
import { GoogleAnalytics } from "nextjs-google-analytics";

let redirectSignIn = "";
let redirectSignOut = "";

const environ = process.env.NEXT_PUBLIC_ENVIRONMENT;
if (environ === "development") {
  redirectSignIn = "https://studio-dev.dvele.com/auth";
  redirectSignOut = "https://studio-dev.dvele.com";
} else if (environ === "production") {
  redirectSignIn = "https://studio.dvele.com/auth";
  redirectSignOut = "https://studio.dvele.com";
}

Amplify.configure({
  Auth: {
    identityPoolId: process.env["NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID"],
    region: process.env["NEXT_PUBLIC_COGNITO_REGION"],
    userPoolId: process.env["NEXT_PUBLIC_COGNITO_USER_POOL_ID"],
    userPoolWebClientId: process.env["NEXT_PUBLIC_COGNITO_APP_CLIENT_ID"],
    oauthSignIn: true,
    oauth: {
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      domain: "auth.studio.dvele.com",
      // * Change this manually to the correct redirect URL for now
      redirectSignIn: redirectSignIn,
      redirectSignOut: redirectSignOut,
      responseType: "token",
    },
  },
});
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: Infinity,
    },
  },
});

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NEXT_PUBLIC_ENVIRONMENT !== "development" && (
        <GoogleAnalytics trackPageViews />
      )}

      <Layout>
        <Component {...pageProps} />
      </Layout>
    </QueryClientProvider>
  );
}
